@import "../../styles/helpers";

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 340px;
    padding: 24px;
    background: $n1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; }
    @include x {
        width: 300px;
        padding: 16px; }
    @include d {
        z-index: 14;
        width: 96px;
        align-items: center; }
    @include m {
        width: 100%;
        align-items: stretch; }
    @include dark {
        background: $n7; }
    &.active {
        @include d {
            align-items: stretch;
            width: 300px;
            box-shadow: 4px 0 32px rgba($n8, .05);
            .item,
            .link {
                width: 100%;
                font-size: 15px;
                svg {
                    margin-right: 12px; } }
            .counter {
                display: block; }
            .toggle {
                position: absolute;
                top: 16px;
                right: 16px;
                svg {
                    fill: $n7;
                    &:first-child {
                        display: none; }
                    &:nth-child(2) {
                        display: inline-block; } } } }
        @include m {
            width: 100%; } } }

.logo {
    display: block;
    width: 48px;
    margin-bottom: 48px;
    @include x {
        margin-bottom: 24px; }
    @include m {
        margin-left: auto; }
    img {
        width: 100%; } }

.menu {
    display: flex;
    flex-direction: column;
    margin-bottom: auto; }

.item,
.dropdown {
    margin-bottom: 8px; }

.item,
.link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
    color: $n4;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: background .2s, box-shadow .2s, color .2s;
    @include d {
        width: 48px;
        font-size: 0; }
    @include m {
        width: 100%;
        font-size: 15px; }
    svg {
        margin-right: 12px;
        fill: $n4;
        transition: fill .2s;
        @include d {
            margin-right: 0; }
        @include m {
            margin-right: 12px; } }
    &:hover,
    &.active {
        color: $n7;
        svg {
            fill: $n7; }
        @include dark {
            color: $n1;
            svg {
                fill: $n1; } } }
    &.active {
        background: $n3;
        box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF;
        @include dark {
            background: $n6;
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11); } } }

.toggle {
    display: none;
    width: 48px;
    height: 48px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include d {
        display: inline-block; }
    @include m {
        display: none; }
    svg {
        fill: $n4;
        &:nth-child(2) {
            display: none; } } }

.close {
    display: none;
    @include m {
        display: block;
        position: absolute;
        top: 16px;
        left: 16px;
        width: 48px;
        height: 48px;
        svg {
            fill: $n7;
            @include dark {
                fill: $n1; } } } }

.foot {
    margin-top: 24px;
    padding-top: 18px;
    border-top: 2px solid $n2;
    @include d {
        margin-top: 12px;
        padding-top: 12px; }
    @include dark {
        border-color: $n6; } }

.theme {
    margin-top: 16px; }

.counter {
    flex-shrink: 0;
    min-width: 24px;
    margin-left: auto;
    border-radius: 6px;
    background: $s2;
    text-align: center;
    line-height: 24px;
    color: $n7;
    @include d {
        display: none; }
    @include m {
        display: block; } }

.overlay {
    @include d {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 12;
        background: rgba($n2, .8);
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include dark {
            background: rgba($n6, .9); }
        &.active {
            visibility: visible;
            opacity: 1; } }
    @include m {
        display: none; } }
